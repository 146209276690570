import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

const Homepage = React.lazy(() => import('../views/homepage'))

const Routes: FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        render={(props: any) => (
          <Homepage {...props}/>
        )}
      />
    </Switch>)
}

export default Routes
