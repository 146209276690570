import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Router } from 'react-router-dom'
import Routes from './routes'
import history from './config/history'
// import './fonts/Sequel-Sans/Sequel-Sans-Black-Body.ttf'
// import './fonts/Sequel-Sans/Sequel-Sans-Bold-Body.ttf'
// import './fonts/Sequel-Sans/Sequel-Sans-Semi-Bold-Body.ttf'
// import './fonts/Sequel-Sans/Sequel-Sans-Medium-Body.ttf'
// import './fonts/Sequel-Sans/Sequel-Sans-Light-Body.ttf'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    {' '}
    <div className="sk-spinner sk-spinner-pulse"> </div>
  </div>
)

// eslint-disable-next-line no-unused-vars
declare global { interface TouchEvent { scale: any; } }

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
  window.document.addEventListener('touchmove', e => {
    if (e.scale !== 1) {
      e.preventDefault()
    }
  }, { passive: false })
}

ReactDOM.render(
  <React.StrictMode>
      <Router history={history}>
      <React.Suspense fallback={loading()}>
        <Routes/>
      </React.Suspense>
      {' '}
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
